/* Updated CSS for Orgs component */

.orgs {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
  }
  
  .list-container {
    display: flex;
    gap: 2rem;
    margin-top: 20px; /* Adjust the margin as needed */
    
  }
  
  .list-item {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Set the width to 100% initially */
  }
  
  .list-item > div {
    flex: 1;
  }
  
  .top-25 {
    margin-left: 2rem; /* Adjust the margin as needed */
  }
  
  .all-groups {
    flex: 7; /* Adjust the value to make it wider */
  }

  .all-groups ul{
    list-style-type: none;
  }
  
  /* Optional: Add media queries for responsiveness */
  @media (max-width: 768px) {
    .list-container {
      flex-direction: column; /* Switch to a column layout on smaller screens */
    }
  }
  
  .orgs h1{
    color: #cab069;
  }

  .orgs h2{
    color: #cab069;
  }

  .list-item-link {
    color: #cab069; /* Set your default text color for list items */
    transition: color 0.3s; /* Add a smooth transition effect for color changes */
  }
  
  .list-item-link:hover {
    color: #f7df9f; /* Set your hover text color for list items */
  }

  .orgs h3 {
    font-size: 2vw;
  }

  .profiles {
    color: white; /* Set the default color */
    transition: color 0.3s; /* Add a smooth transition effect for color changes */
  }
  
  .profiles:hover {
    color: #cab069; /* Set your hover text color for the link */
  }

  .reportClassOrgs {
    width: 43vw; /* Adjust the width as needed */
    height: calc(90vw * .65); /* Adjust the height as needed */
    /* Add any other styles you want to apply */
    
}

.alphabet-filter {
  margin: 1rem 0;
  display: flex;
  white-space: nowrap; /* Prevent wrapping to the next line */
  gap: 0.5rem;
}

.alphabet-filter .letter {
  cursor: pointer;
  padding: 0.1rem;
  background-color: #333;
  color: white;
  border-radius: 0.25rem;
}

.alphabet-filter .letter:hover {
  background-color: #555;
}

.alphabet-filter .letter.selected {
  background-color: #555; /* Change background color to highlight selected letter */
  color: #cab069; /* Change text color for selected letter */
}

.contact-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 640px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

  
