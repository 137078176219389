.reports h1{
    color: #cab069;
}

/* Define styles for the reports container */
.reports {
  width: 90%; /* Set the initial width of the container */
  margin: auto; /* Center the container horizontally */
}

/* Apply these styles to your radio buttons and labels */
label {
    display: flex;
    align-items: center;
    margin-bottom: 0px; /* Adjust margin as needed */
  }
  
  input[type="radio"] {
    margin-right: .1vw; /* Adjust margin as needed */
    width: 20px; /* Set the desired width for the radio button */
    height: 20px; /* Set the desired height for the radio button */
  }
  
  /* Optional: Add styles for label text */
  label span {
    font-size: 2.2vw; /* Set the desired font size for the label text */
    /* Add any other styles as needed */
  }
  
  /* Apply these styles to your search box and label */
h3 {
    text-align: center; /* Center the label text */
  }
  
  input[type="text"] {
    width: 150px; /* Set the desired width for the search box */
    height: 5px;
    padding: 10px; /* Set padding as needed */
    margin-bottom: -5px; /* Adjust margin as needed */
  }
  
  /* Optional: Center the search box horizontally */
  /* You can use different methods based on your layout */
  /* Example using flexbox */
  .search-container {
    display: flex;
    justify-content: center;
  }

  /* styles.css */

.advanced-search-container {
    display: flex;
    justify-content: space-between;
  }
  
  .advanced-search-label {
    margin-bottom: 10px;
    margin-right: 10px;
  }
  
  .advanced-search-dropdown-container {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  
  .advanced-search-dropdown-label {
    margin-right: 10px;
  }

  .search-boxes {
    margin-left: 10px;
  }

  .search-date-picker{
    margin-left: 10px;
    margin-top: 5px;
    height: 25px;
  }

  /* Apply styles directly to the select element */
#dropdownFilter {
    width: 200px !important; /* Use !important to override other styles if needed */
    height: 25px;
    margin-top: 5px;
  }

  #industryFilter {
    width: 200px !important; /* Use !important to override other styles if needed */
    height: 25px;
    margin-top: 5px;
  }

  .clear-button{
    margin-bottom: -10px;
  }
  
  .column-report {
    width: 55%; /* Adjust the width of columns as needed  #282c34*/
    border: 1px solid #282c34;
    margin: 10px;
    margin-left: 100px;
    margin-right: 25px;
  }

  .ad-header {
    display: block !important;
}

  .ad-header h2{
    color:#cab069;
    
  }

  .reportClass-ticker {
    width: 100%; /* Adjust the width as needed */
    height: calc(50vw * 200/832); /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    /* Add any other styles you want to apply */
}  

.region-text {
  font-size: 25px; /* Adjust to your preferred size */
  font-weight: normal; /* Optionally modify font weight */
}

.reports-title{
  margin-top: -10px;
}
