.page-header {
    color: #cab069;
}

.resources h3 {
    color: #cab069;
}

.resources h2 {
    color: #cab069;
}

.send {
    background-color: #cab069;
}

.reason-for-contact {
    text-align: right;
    margin-right: 10px;
    margin-left: 0;
    white-space: nowrap;
}

.form-input-dropdown {
    width: 100%;
    height: 35px;
    max-width: calc(100% - 160px);
    align-items: center;
    margin-top: 5px;
    
}

.form-input-name {
    width: 100%;
    height: 25px;
    max-width: calc(100% - 160px);
}

/* Additional styles from ContactUs.js */
.resources {
    display: flex;
    gap: 20px;
}

.column {
    width: 75%;
}

.column h2 {
    margin-bottom: 10px;
}

.column p {
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.form-label {
    width: 100px;
    text-align: right;
    margin-right: 75px;
}

.form-input {
    flex: 1;
    height: 10px;
    max-width: calc(100% - 160px);
}

.form-submit {
    margin-top: 10px;
}

.form-textarea {
    width: 100%;
    max-width: calc(100% - 160px);
}

.faq-question {
    cursor: pointer;
  }
  
  .faq-question.expanded {
    background-color: #cab069;
  }

  .faq-RFI {
    cursor: pointer;
  }
  
  .faq-RFI.expanded {
    background-color: #cab069;
  }

  .contact-us {
    width: 95%; /* Set the initial width of the container */
    margin: auto; /* Center the container horizontally */
  }

  .faq-RFI {
    font-size: 1.1vw; /* Set the desired font size */
    font-weight: normal; /* Remove bold */
    text-align: left; /* Align text to the left */
  }

  .faq-question{
    font-size: 1.5vw; /* Set the desired font size */
    font-weight: normal; /* Remove bold */
    text-align: left; /* Align text to the left */
  }

  .faq-answer { 
    font-size: 2vh;
  }