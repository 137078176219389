.list-item{
    padding: 0;
    display: flex;
    flex-wrap: wrap;   
    list-style: none; 
    }

    .list-item li {
        margin-right: 10px;
        flex: 0 0 auto;
        width: calc(90% - 10px);
        box-sizing: border-box;
        text-align: left;
    }
    
    .list-item a{
    color:#ffffff; 
    list-style:none;
    font-size: 1.6vw;
    text-decoration: none;
    }

    .highlights h1{
        color: #cab069;
    }
    .highlights h2{
        color: #cab069;
    }

    .highlights ul.list-item li a:hover {
        color: #cab069; /* Set your hover text color for links */
        transition: color 0.3s; /* Add a smooth transition effect for color changes */
      }
      
      .reportClassar {
        width: 86vw; /* Adjust the width as needed */
        height: calc(90vw * 936/1664); /* Adjust the height as needed */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .reportClass-complaints {
        width: 86vw; /* Adjust the width as needed */
        height: calc(90vw * 936/1664); /* Adjust the height as needed */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .highlights {
        width: 86vw; /* Adjust the width as needed */
        /* Add any other styles you want to apply */
    }

    .box {
        border: 0px solid #ccc; /* Add a border around the box */
        padding: 0px; /* Add padding inside the box */
    }

    .box p {
        font-size: 1.3vw;
    }

    .list-item li {
        flex: 0 0 auto; /* Allow items to grow and shrink as needed */
        width: calc(100% - 10px); /* Adjust the width of each item, considering the margin */
        box-sizing: border-box; /* Include padding and border in the width calculation */
        border-bottom: 1px solid #ccc; /* Add a border line between each item */
        margin-bottom: .5vh;
        margin-top: .5vh;
    }

    .list-item li a {
        text-decoration: none;
        display: block;
        padding: 10px;
    }

    .left-align-list {
        text-align: left;
        list-style-position: inside; /* Ensures bullet points are inside the content area */
        padding-left: 0; /* Optional: To remove default padding */
      }

      .disclaimer-text{
        font-size: large;

      }