.report-item a {
    color:#ddd;
    font-size: 1vw;
  }

/* Adjust the color of the "+X more" button in React Big Calendar */
.rbc-show-more {
    color: #cab069;; /* Change the text color to white */
    background-color: #282c34;
    /* Add any other desired styling properties */
  }
  
  .rbc-overlay {
    /* Other existing styles */
    left: 10px !important; /* Adjust this value */
    min-width: 200px; /* Adjust as needed */
    /* Other existing styles */
    color: white;
    background-color: #282c34;
  }

  .your-element .rbc-event {
    background-color: #282c34; /* Set the background color for the titles */
    color: #282c34; /* Change the text color to white */
  }
  
  .rbc-toolbar button {
    color: #d8dbdd;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
  }

  .rbc-toolbar button:hover {
    color: #373a3c;
    background-color: #cab069;
    border-color: #adadad;
  }

  .rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #424b5e;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }

  .rbc-today {
    background-color: #cab069;
  }

  .rbc-off-range-bg {
    background: #2e2e2e;
  }

  .calendar h1 {
    color: #cab069;
  }

  .rbc-toolbar-label{
    font-size: xxx-large;
  }

  .calendar {
    width: 90%;
  }
  
  .calendar-wrapper {
    height: 90vh; /* Adjust as needed */
    width: 100%;
  }

  /* Hide time slots in Week and Day views */
.rbc-time-content {
  display: none; /* Hide the time grid */
}

/* Optional: Hide the time labels on the left */
.rbc-timeslot-group {
  display: none;
}

.rbc-header {
  border-bottom: 1px solid #ddd;
  flex: 1 0;
  font-size: 90%;
  font-weight: 700;
  min-height: 50px;
  overflow: hidden;
  padding: 0 3px;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.rbc-day-slot .rbc-background-event, .rbc-event {
  background-color: #424b5e;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  margin: 0;
  padding: 2px 5px;
  text-align: left;
  width: 100%;
  margin-top: 15px;
}

.rbc-toolbar button.rbc-active, .rbc-toolbar button:active {
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  color: black;
}

