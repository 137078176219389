/* ReportsGrid.css */

.reports-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .reports-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #444444;
    color: white;
    padding: 10px;
    font-weight: bold;
  }
  
  .header-item {
    flex: 1;
    text-align: center;
  }
  
  .report-row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 10px;

  }
  
  .report-row a{
    color:#ddd;
    font-size: 16px;
    text-decoration: underline;
  }


  .report-item {
    flex: 1;
    text-align: center;
    text-decoration: none;
    font-size: 16px
  }
  
  .report-item a {
    color:#ddd;
    font-size: 32px;
  }

  .report-title {
    text-decoration: none;
    color: #000; /* Set your default text color for report titles */
    transition: color 0.3s; /* Add a smooth transition effect for color changes */
  }

  .report-title:hover {
    color: #cab069; /* Set your hover text color for report titles */
  }