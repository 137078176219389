/* Navbar.css */

/* Navbar styles */
.navbar {
  background-color: #2c2c2c;
  color: #cab069;
  padding: 1rem;
  display: flex;
  font-size: 1.5vw;
  align-items: center;
  position: sticky;
  top: 0;
  justify-content: space-between;
  z-index: 100; /* Set a higher z-index to ensure it appears above other elements */
}

.logo {
  height: 4vw;
}

.navbar-item {
  color: #cab069;
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
  text-decoration: none;
  justify-content: space-between;
  white-space: nowrap; /* Prevent items from breaking to a new line */
}

.navigation {
  margin-right: 7px;
  margin-left: 7px;
}

.client-link {
  margin-left: 20px;
}

.user-info {
  text-align: right;
  align-items: center;
}

.user-info p,
.user-info button {
  margin: 0;
}

.logout-btn,
.login-btn {
  font-size: 1vw;
  padding: 10px 20px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  .navbar-item {
    margin: 5px 0;
    width: 100%; /* Make items take full width on smaller screens */
  }
}

/* Dropdown styles for "Regions" */
.navigation {
  position: relative;
  display: inline-block;
}

.navigation .dropdown {
  display: none;
  position: absolute;
  background-color: #2c2c2c; /* Change background color to black */
  color: #fff; /* Text color */
  font-size: 14px; /* Adjust font size as needed */
  min-width: 120px; /* Adjust dropdown width as needed */
  z-index: 1;
}

.navigation:hover .dropdown {
  display: block; /* Show dropdown on hover */
  z-index: 1000;
}

.navigation .dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navigation .dropdown ul li {
  padding: 8px 12px;
}

.navigation .dropdown ul li a {
  color: #fff; /* Set link color to white */
  text-decoration: none; /* Remove default link underline */
  transition: color 0.3s; /* Add a smooth transition effect for color changes */
}

/* Apply hover effect to dropdown items */
.navigation .dropdown ul li:hover a {
  color: #f7df9f !important; /* Set your hover text color for dropdown items */
}

.navigation .dropdown ul li:hover {
  background-color: #333; /* Hover background color */
}

.client-link {
  border: 1px solid rgb(255, 255, 255);
  padding: 2px;
}

