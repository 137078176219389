.report-item {
    flex: 1;
    text-align: center;
    text-decoration: none;
    font-size: 16px
  }
  
  .report-item a {
    color:#ddd;
    font-size: 32px;
  }

  .title h2{
    color: #cab069;
  }

  .umbrella {
    width: 90%; /* Set the initial width of the container */
    margin: auto; /* Center the container horizontally */
  }