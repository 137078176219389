.banner {
    background-color: #474747;
    padding: 10px;
    text-align: center;
    width: 100%;
    z-index: 999; /* Ensure it's above other elements */
    color: #cab069;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .disclaimer-text {
    margin: 0;
    flex: 1;
  }
  
  .close-button {
    cursor: pointer;
    color: #cab069;
    margin-right: 25px; /* Add margin to move the close button to the right */
  }
  