

.reportClass {
    width: 86vw; /* Adjust the width as needed */
    height: 85vh;
    /* Add any other styles you want to apply */
}


 
.stats-header{
    color: #cab069;  
    margin-top: 0px;
 
 
}
 
.App-header{
    justify-content: flex-start;
}

.power-bi {
    width: 87%; /* Set the initial width of the container */
    margin: auto; /* Center the container horizontally */
    margin-bottom: 5vw;
  }
