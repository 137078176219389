.list-item-home{
    text-align:left;
    list-style:none;    
    font-size: 1.2vw;
    }
    
    .list-item-home a{
    color:#ffffff; 
    list-style:none;
    }

    .list-item-home li a {
        text-decoration: none;
        display: block;
        padding: 10px;
    }

    .list-item-home li {
        margin-right: 10px; /* Adjust spacing between items as needed */
        flex: 0 0 auto; /* Allow items to grow and shrink as needed */
        width: calc(90% - 10px); /* Adjust the width of each item, considering the margin */
        box-sizing: border-box; /* Include padding and border in the width calculation */
        border-bottom: 1px solid #ccc; /* Add a border line between each item */
    }
    

    .home-highlights{
        width: 100%;
    }


    .home-highlights ul.list-item-home li a:hover {
        color: #cab069; /* Set your hover text color for links */
        transition: color 0.3s; /* Add a smooth transition effect for color changes */
      }
