/* Example CSS - Style your columns as needed */
.columns {
    display: flex;
    justify-content: space-between;
  }
  
  .column {
    width: 33%; /* Adjust the width of columns as needed */
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px;
    margin-bottom: 20px;
  }

.column h2 {
  color: #cab069; /* Change this to your desired color */
}

.background-image{
  background-image: url(../Images/BackgroundImage.png);
  height: 70vh;
  background-size: cover;
}

.reportClassHome {
  width: 100%; /* Adjust the width as needed */
  height: 70vh; /* Adjust the height as needed */
  /* Add any other styles you want to apply */
}