/* Footer.css */
.footer {
    background-color: #2c2c2c;
    color: white;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
  }

  .links-list {
    display: flex;
    gap: 10px;
  }

  .links-list a {
    color: white;
    text-decoration: none;
  }

  .links-list ul {
    list-style: none;
    padding: 0;
  }
  
  .links-list li {
    margin-bottom: 5px; /* Adjust the margin between list items */
    font-size: 18px; /* Set the desired font size */
    transition: color 0.3s; /* Smooth transition for color change */
  }

  .links-list li a {
    text-decoration: none;
    color: white; /* Set your default text color */
  }
  
  .links-list li:hover a {
    color: #cab069; /* Set your hover text color */
  }

  .links-list li:hover {
    color: #cab069; /* Set your desired highlight color on hover */
  }

  .contact-info {
    margin-bottom: 10px; /* Add margin between contact info and copyright info */
  }

  .contact-info,
.links-list {
  margin: 0 10px; /* Add some margin between the two columns */
}

.line {
  height: 125px; /* Set the height of the line */
  width: 1px; /* Set the width of the line */
  background-color: #ccc; /* Set the color of the line */
  margin: 0 10px; /* Adjust the margin on both sides of the line */
}
  
  .copyright-info {
    width: 100%; /* Make the copyright info span the full width */
    text-align: center; /* Center the text */
    margin-top: 10px; /* Add margin above the copyright info */
  }
  
  .footer-content a {
    color: white;
    text-decoration: none;
  }

  .header{
    font-size: 28px;
    color:#cab069;
    margin-top: -20px;
    margin-bottom: -5px;
    text-align: left;
  }